import * as Sentry from "@sentry/browser";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const generateHeaders = () => {
  const headers = { "Content-Type": "application/json" };

  if (window && window.netlifyIdentity.currentUser()) {
    return window.netlifyIdentity
      .currentUser()
      .jwt()
      .then((token) => {
        return { ...headers, Authorization: `Bearer ${token}` };
      });
  }

  return Promise.resolve(headers);
};

const fetchWithHeaders = (path) => {
  return new Promise((resolve, reject) => {
    generateHeaders().then((headers) => {
      fetch(path, {
        method: "GET",
        headers,
      })
        .then((response) => {
          if (response.status !== 200) {
            return response.text().then((text) => {
              // Make sure we know about all non-200s
              Sentry.captureException(new Error(`POST error: ${text}`));

              try {
                // We've seen cases where a non-JSON response is returned.
                const error = JSON.parse(text);
                return reject(new Error(error.message));
              } catch (e) {
                return reject(new Error(text));
              }
            });
          }

          return response.json();
        })
        .then(resolve)
        .catch(reject);
    });
  });
};

const post = (path, body) => {
  return new Promise((resolve, reject) => {
    generateHeaders().then((headers) => {
      fetch(path, {
        method: "POST",
        headers,
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.status !== 200) {
            return response.text().then((text) => {
              // Make sure we know about all non-200s
              Sentry.captureException(new Error(`POST error: ${text}`));

              try {
                // We've seen cases where a non-JSON response is returned.
                const error = JSON.parse(text);
                return reject(new Error(error.message));
              } catch (e) {
                return reject(new Error(text));
              }
            });
          }

          return response.json();
        })
        .then(resolve)
        .catch(reject);
    });
  });
};

const updateRSVP = ({
  id,
  status,
  vegetarian,
  vegan,
  glutenFree,
  songRequest,
  songRequestID,
  email,
  guestName
}) => {
  return post("/.netlify/functions/update-rsvp", {
    id,
    status,
    vegetarian,
    vegan,
    glutenFree,
    songRequest,
    songRequestID,
    email,
    guestName
  });
};

const search = (query) => {
  return new Promise((resolve, reject) => {
    fetch(`/.netlify/functions/search?${encode({ query })}`)
      .then((response) => response.json())
      .then(resolve);
  });
};

const getHelp = ({ name, message }) => {
  return post("/.netlify/functions/get-help", {
    name,
    message,
  });
};

const songSearch = (query) => {
  return new Promise((resolve, reject) => {
    fetch(`/.netlify/functions/song-search?${encode({ query })}`)
      .then((response) => response.json())
      .then(resolve);
  });
};

const getSong = (trackId) => {
  return new Promise((resolve, reject) => {
    fetch(`/.netlify/functions/get-song?${encode({ trackId })}`)
      .then((response) => response.json())
      .then(resolve);
  });
};

const spotifyAuthUrl = () => {
  return fetchWithHeaders("/.netlify/functions/spotify-auth-url");
};

const spotifyAccessToken = (code) => {
  return new Promise((resolve, reject) => {
    fetch("/.netlify/functions/spotify-access-token", {
      method: "POST",
      body: JSON.stringify({ code }),
    })
      .then((response) => response.json())
      .then(resolve);
  });
};

const syncWeddingPlaylist = (accessToken) => {
  return post("/.netlify/functions/sync-wedding-playlist", { accessToken });
};

export {
  search,
  updateRSVP,
  getHelp,
  songSearch,
  getSong,
  spotifyAuthUrl,
  spotifyAccessToken,
  syncWeddingPlaylist,
};
